

const flightDetailsSectionsClass = '.c-flights-properties__container';
const toggleButtonClass = '.c-flights-properties__link';

function init() {
    const flightDetailsSections = document.querySelectorAll(flightDetailsSectionsClass);
    const toggleButton = document.querySelector(toggleButtonClass);

    if (flightDetailsSections.length > 0 && toggleButton) {
        toggleButton.addEventListener('click', function (e) {
            e.preventDefault();
            toggleFlightDetails(flightDetailsSections, toggleButton);
        });
    }
}

function toggleFlightDetails() {
    const flightDetailsSections = $(flightDetailsSectionsClass);
    const toggleButton = document.querySelector(toggleButtonClass);

    flightDetailsSections.each(function() { $(this).toggleClass('c-flights-properties__container--active') });
    toggleButton.textContent = toggleButton.textContent.includes('Show Flight Details') ? 'Hide Flight Details' : 'Show Flight Details';
}

export default init;
export {toggleFlightDetails}